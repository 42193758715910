$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;
$background-color: #f8f8f8;
$foreground-color: white;
$input-background: white;

$dark-btn-background: #131113;
$light-btn-background: #ececec;

$button-text-color: #fff;

$theme-color-1: #145388;
$theme-color-2: #2a93d5;
$theme-color-3: #6c90a1;
$theme-color-4: #365573;
$theme-color-5: #47799a;
$theme-color-6: #8e9599;

$primary-color: #3a3a3a;
$secondary-color: #8f8f8f;
$muted-color: #909090;

$gradient-color-1: #1f5c8d;
$gradient-color-2: #145388;
$gradient-color-3: #285172;

$lp-bg-color-1: #002646;
$lp-bg-color-2: #003a62;
$lp-bg-color-3: #016da9;
$lp-bg-color-4: #0193de;

$shadowOffsetsTop: 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom: 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

$logoPath: './img/synergi-logo.png';
$logoPathMobile: './img/synergi-logo.png';

$lpLogoPath: './img/synergi-logo.png';
$lpLogoPathPinned: './img/synergi-logo.png';

@import '../_mixins.scss';
@import '../_synergi.style.scss';
